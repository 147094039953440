import axios from 'axios';				//引入axios
import router from '../router';			//引入路由
import serveApi from '../serve.js'
// import store from '../store/index';		//引入我的Vuex配置的文件
import { Message } from "element-ui"      //基于element-ui的弹框组件

/**
   * 提示函数
   * 显示一秒后关闭
   */
const tip = msg => {
    Message({
        message: msg,
        duration: 1000,
        type: "error"
    });
}

/**
  * 跳转登录页
  * 携带当前页面路由，在登录页面完成登录后返回当前页面
  */
const toLogin = () => {
    router.replace({
        path: '/Login',
        query: {
            redirect: router.currentRoute.fullPath
        }
    });
};

// 服务器状态码不是2开头的的情况
// 这里可以跟你们的后台开发人员协商好统一的错误状态码
// 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
// 下面列举几个常见的操作，其他需求可自行扩展
/**
  * 请求失败后的错误统一处理
  * @param {Number} status 请求失败的状态码
  */

const errorHandle = (status, other) => {

    // 状态码判断
    switch (status) {
        // 401: 未登录状态，跳转登录页
        case 401:
            localStorage.clear();
            Message({
                message: '登录过期',
                duration: 1000,
                type: "error"
            });
            toLogin();
            break;
        // 403 token过期
        // 清除token并跳转登录页
        case 403:
            tip('登录过期，请重新登录');
            // 清除token
            localStorage.removeItem('adminToken');
            // store.commit('changeLogin', null);
            setTimeout(() => {
                toLogin();
            }, 1000);
            break;
        // 404请求不存在
        case 404:
            tip('请求的资源不存在');
            break;

        default:
        // tip(other.msg);

        // console.log(other);
    }
}

// 创建axios实例
var instance = axios.create({
    baseURL: serveApi.api,
    timeout: 1000 * 30
});

// 设置post请求头
// instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
/**
  * 请求拦截器
  * 每次请求前，如果存在token则在请求头中携带token
  */
instance.interceptors.request.use(
    config => {
        // 登录流程控制中，根据本地或者localStorage中是否存在token判断用户的登录情况
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
        // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
        const adminToken = localStorage.getItem('adminToken');
        adminToken && (config.headers.adminToken = adminToken);

        return config;
    },
    error => Promise.error(error)
)
// 响应拦截器

instance.interceptors.response.use(
    // 请求成功
    // res => res.data.code === 200 ? Promise.resolve(res) : Promise.reject(res),
    res => {
        const code = res.data.code

        // if (code !== 200) {
        //     errorHandle(Number(res.data.code), res.data)
        //     return Promise.reject(res.data)


        // }
        // else {
        //     return Promise.resolve(res.data)

        // }
        if (res.data.status || res.config.responseType) {
            return Promise.resolve(res.data)
            // return res

        } else {
            errorHandle(Number(res.data.code))
            tip(res.data.msg)
            return Promise.reject(res.data)
        }
    },
    // 请求失败
    error => {
        // debugger
        const { response } = error;
        if (response) {
            // 请求已发出，但是不在2xx的范围
            errorHandle(response.status, response.data.message);
            if (response.data.msg) {
                tip(response.data?.msg)
            } else {
                tip('请求超时')
            }
            return Promise.reject(response);
        } else {
            // 处理断网的情况
            // eg:请求超时或断网时，更新state的network状态
            // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
            // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
            // store.commit('changeNetwork', false);
            tip('网络异常')
            // toLogin();

        }
    }
);

export default instance;